import style from './Step4.module.scss';

// React
import { useEffect, useState } from 'react';

// Ant Design
import { message as antdMessage } from 'antd';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Redux
import { useSelector } from 'react-redux';
import { API_SERVER_WEBSOCKET } from '../../../http';
import { useAppDispatch } from '../../../redux/store';
import { setRates } from '../../../redux/rates/slice';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { selectWallets } from '../../../redux/wallets/selectors';
import { TransactionFormData } from '../../../redux/transaction/types';
import { selectTransaction } from '../../../redux/transaction/selectors';
import { setBtcBalance, setTetherBalance, setTonBalance } from '../../../redux/wallets/slice';
import { requestTransactionCreateBitcoin, requestTransactionCreateTon, requestTransactionCreateUsdt } from '../../../redux/transaction/asyncActions';
import { Status } from '../../../utils/utils';


const Step4 = () => {
    const navigate = useNavigate();
    const [sum, setSum] = useState<number>();

    let { type, cryptoName, action, wallet } = useParams();

    // WebSockets Settings
    const token = localStorage.getItem('access_token');

    // Redux
    const dispatch = useAppDispatch();

    // Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Get Wallets
    const { btc_wallet, ton_wallet, usdt_wallet } = useSelector(selectWallets);

    // Get Balance Bitcoin
    const WS_BTC_BALANCE_URL = `${API_SERVER_WEBSOCKET}/bitcoin/balance/ws`;
    const { lastJsonMessage: lastJsonMessageBtcBalance, readyState: readyStateBtcBalance } = useWebSocket(
        WS_BTC_BALANCE_URL,
        {
            share: false,
            reconnectInterval: 5000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        }
    )

    useEffect(() => {
        if (readyStateBtcBalance === ReadyState.OPEN) {
            if (lastJsonMessageBtcBalance && typeof lastJsonMessageBtcBalance === 'string') {
                const balanceBtn = JSON.parse(lastJsonMessageBtcBalance);
                dispatch(setBtcBalance({
                    address: balanceBtn['address'],
                    balance_usd: balanceBtn['balance_usd'],
                    balance_crypto: balanceBtn['balance_crypto'],
                }))
            }
        }
    }, [readyStateBtcBalance, lastJsonMessageBtcBalance, dispatch])

    // Get Balance Ton
    const WS_TON_BALANCE_URL = `${API_SERVER_WEBSOCKET}/ton/balance/ws`;
    const { lastJsonMessage: lastJsonMessageTonBalance, readyState: readyStateTonBalance } = useWebSocket(
        WS_TON_BALANCE_URL,
        {
            share: false,
            reconnectInterval: 5000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        }
    )

    useEffect(() => {
        if (readyStateTonBalance === ReadyState.OPEN) {
            if (lastJsonMessageTonBalance && typeof lastJsonMessageTonBalance === 'string') {
                const balanceTon = JSON.parse(lastJsonMessageTonBalance);
                dispatch(setTonBalance({
                    address: balanceTon['address'],
                    balance_usd: balanceTon['balance_usd'],
                    balance_crypto: balanceTon['balance_crypto'],
                }))
            }
        }
    }, [readyStateTonBalance, lastJsonMessageTonBalance, dispatch])

    // Get Balance Tether
    const WS_TETHER_BALANCE_URL = `${API_SERVER_WEBSOCKET}/usdt/balance/ws`;
    const { lastJsonMessage: lastJsonMessageTetherBalance, readyState: readyStateTetherBalance } = useWebSocket(
        WS_TETHER_BALANCE_URL,
        {
            share: false,
            reconnectInterval: 5000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        }
    )

    useEffect(() => {
        if (readyStateTetherBalance === ReadyState.OPEN) {
            if (lastJsonMessageTetherBalance && typeof lastJsonMessageTetherBalance === 'string') {
                const balanceTether = JSON.parse(lastJsonMessageTetherBalance);
                dispatch(setTetherBalance({
                    address: balanceTether['address'],
                    balance_usd: balanceTether['balance_usd'],
                    balance_crypto: balanceTether['balance_crypto'],
                }))
            }
        }
    }, [readyStateTetherBalance, lastJsonMessageTetherBalance, dispatch])

    const maxSumHandle = () => {
        if (cryptoName === 'btc') {
            setSum(btc_wallet?.balance_crypto);
        } else if (cryptoName === 'ton') {
            setSum(ton_wallet?.balance_crypto);
        } else if (cryptoName === 'usdt') {
            setSum(usdt_wallet?.balance_crypto);
        }
    }

    // Get Rates
    const WS_RATE_URL = `${API_SERVER_WEBSOCKET}/rate/ws`;
    const { lastJsonMessage: lastJsonMessageRate, readyState: readyStateRate } = useWebSocket(
        WS_RATE_URL,
        {
            share: false,
            reconnectInterval: 2000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        },
    )

    useEffect(() => {
        if (readyStateRate === ReadyState.OPEN) {
            if (lastJsonMessageRate && typeof lastJsonMessageRate === 'string') {
                const rates = JSON.parse(lastJsonMessageRate);
                dispatch(setRates({
                    tonUsd: rates['the-open-network/usd']?.rate,
                    tonRub: rates['the-open-network/rub']?.rate,
                    bitcoinUsd: rates['bitcoin/usd']?.rate,
                    bitcoinRub: rates['bitcoin/rub']?.rate,
                    tetherUsd: rates['tether/usd']?.rate,
                    tetherRub: rates['tether/rub']?.rate,
                }));
            }
        }
    }, [readyStateRate, lastJsonMessageRate, dispatch])

    // Создания Транзакции
    const { status, message } = useSelector(selectTransaction);
    const [disableTransfer, setDisableTransfer] = useState(false);

    const transferValue = () => {
        setDisableTransfer(!disableTransfer);

        if (!sum) {
            messageApi.error('Введите сумму');
            setDisableTransfer(false);
            return;
        }
        if (cryptoName === 'btc' && wallet) {
            if (sum && btc_wallet) {
                if (sum > btc_wallet?.balance_crypto) {
                    messageApi.error('У Вас недостаточно средств');
                    setDisableTransfer(false);
                    return;
                }
                if (sum <= 0) {
                    messageApi.error('Сумма не может быть меньше нуля');
                    setDisableTransfer(false);
                    return;
                }
            }

            const formData: TransactionFormData = {
                address_to: wallet,
                amount: sum,
            }
            dispatch(requestTransactionCreateBitcoin(formData));
        } else if (cryptoName === 'ton' && wallet) {
            if (sum && ton_wallet) {
                if (sum > ton_wallet?.balance_crypto) {
                    messageApi.error('У Вас недостаточно средств');
                    setDisableTransfer(false);
                    return;
                }
                if (sum <= 0) {
                    messageApi.error('Сумма не может быть меньше нуля');
                    setDisableTransfer(false);
                    return;
                }
            }
            const formData: TransactionFormData = {
                address_to: wallet,
                amount: sum,
            }
            dispatch(requestTransactionCreateTon(formData));
        } else if (cryptoName === 'usdt' && wallet) {
            if (sum && usdt_wallet) {
                if (sum > usdt_wallet?.balance_crypto) {
                    messageApi.error('У вас недостаточно средств');
                    setDisableTransfer(false);
                    return;
                }
                if (sum <- 0) {
                    messageApi.error('Сумма не может быть меньше нуля');
                    setDisableTransfer(false);
                    return;
                }
            }
            const formData: TransactionFormData = {
                address_to: wallet,
                amount: sum,
            }
            dispatch(requestTransactionCreateUsdt(formData));
        }
    }

    useEffect(() => {
        if (status === Status.ERROR && message.length > 0) {
            messageApi.error(message);
            setDisableTransfer(false);
        } else if (status === Status.SUCCESS) {
            messageApi.success(message);
            setTimeout(() => {
                setDisableTransfer(false);
                navigate('/home');
            }, 4000)
        }
    }, [status, message])

    return (
        <div className={style.step4}>
            {contextHolder}
            <BackButton onClick={() => navigate(-1)} />
            <h2>Отправить</h2>
            <div className={style.step4__content}>
                <input
                    type="number"
                    value={sum}
                    placeholder='Введите сумму'
                    onChange={(e) => setSum(Number(e.target.value))}
                />
                <button onClick={() => maxSumHandle()}>Макс</button>
            </div>
            <div className={style.step4__btn}>
                <button disabled={disableTransfer} onClick={() => transferValue()}>Отправить</button>
            </div>
        </div>
    )
}

export default Step4;