import style from './Profile.module.scss';

// Axios
import axios from 'axios';

// Ant Design
import { message as antdMessage } from 'antd';

// Telegram
import { useWebApp } from '@vkruglikov/react-telegram-web-app';

// Images
import circleImg from '../../assets/img/circle.png';
import deleteImg from '../../assets/img/delete.png';

import tonImg from '../../assets/img/wallet/ton.svg';
import tetherImg from '../../assets/img/wallet/tether.svg';
import bitcoinImg from '../../assets/img/wallet/bitcoin.svg';


const Profile = () => {
    // Telegram
    const WebApp = useWebApp();

    // Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    const addCard = () => {
        const jwtFinFreshToken = localStorage.getItem('jwt_token_finfresh');
        if(!jwtFinFreshToken) {
            messageApi.info('Пройдите верификацию, перед добавлением карты');
            return;
        }

        axios.get('https://crm.fin-fresh.ru/api/pay/link?redirect_url=https://google.com/', {
            headers: {
                'Authorization': `Bearer ${jwtFinFreshToken}`
            }
        })
        .then((res) => {
            const link = res.data.data.link;
            console.log('link: ', link);
            if (link) {
                WebApp.openTelegramLink(link);
                // WebApp.openLink(link);
            }
        })
        .catch((err) => {
            console.log('err: ', err);
            if (err.response.data.message) {
                messageApi.error(err.response.data.message);
            }
        })
    }

    return (
        <div className={style.profile}>
            {contextHolder}
            <h2>Профиль</h2>
            <div className={style.profile__linked_cards}>
                <div className={style.profile__linked_cards__header}>
                    <h3>Привязанные карты</h3>
                    <button onClick={addCard}>Добавить</button>
                </div>
                <div className={style.profile__linked_cards__cards}>
                    <div className={style.profile__linked_cards__card}>
                        <div className={style.left_block}>
                            <img src={circleImg} alt="circle-card" />
                            <p>•••• 2959</p>
                        </div>
                        <div className={style.right_block}>
                            <button>Удалить <img src={deleteImg} alt="delete-img" /></button>
                            
                        </div>
                    </div>
                    <div className={style.profile__linked_cards__card}>
                        <div className={style.left_block}>
                            <img src={circleImg} alt="circle-card" />
                            <p>•••• 2959</p>
                        </div>
                        <div className={style.right_block}>
                            <button>Удалить <img src={deleteImg} alt="delete-img" /></button>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.profile__current_loan}>
                <div className={style.profile__current_loan__header}>
                    <h3>Текущие займы</h3>
                </div>
                <div className={style.profile__current_loan__loans}>
                    <div className={style.profile__current_loan__loan}>
                        <div className={style.left_block}>
                            <img src={tetherImg} alt="terher-img" />
                            <p>USDT</p>
                        </div>
                        <div className={style.right_block}>
                            <p>10 USDT</p>
                            <p>2723 ₽</p>
                        </div>
                    </div>

                    <div className={style.profile__current_loan__loan}>
                        <div className={style.left_block}>
                            <img src={tonImg} alt="ton-img" />
                            <p>TON</p>
                        </div>
                        <div className={style.right_block}>
                            <p>10 TON</p>
                            <p>2723 ₽</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;