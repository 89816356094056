import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../utils/utils';
import { TransactionSliceState } from './types';
import { requestGetBitcoinTransactions, requestGetTonTransactions, requestGetUsdtTransactions, requestTransactionCreateBitcoin, requestTransactionCreateTon, requestTransactionCreateUsdt } from './asyncActions';


const initialState: TransactionSliceState = {
    message: '',
    status: Status.CALM as Status,
    transactions: []
}

const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        setNullTransaction(state) {
            state.message = '';
            state.status = Status.CALM;
        },
        setNullTransactions(state) {
            state.message = '';
            state.status = Status.CALM;
            state.transactions = [];
        }
    },
    extraReducers: (builder) => {
        // Bitcoin Create Transaction
        builder.addCase(requestTransactionCreateBitcoin.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestTransactionCreateBitcoin.fulfilled, (state, action) => {
            if (action.payload.detail === 'Транзакция успешно создана') {
                state.status = Status.SUCCESS;
                state.message = action.payload.detail;
            } else {
                state.status = Status.ERROR;
                state.message = action.payload.detail;
            }
        })
        builder.addCase(requestTransactionCreateBitcoin.rejected, (state, action) => {
            state.status = Status.ERROR;
            state.message = 'Произошла ошибка при создании транзакции Bitcoin';
        });
        // Ton Create Transaction
        builder.addCase(requestTransactionCreateTon.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestTransactionCreateTon.fulfilled, (state, action) => {
            if (action.payload.detail === 'Транзакция успешно создана') {
                state.status = Status.SUCCESS;
                state.message = action.payload.detail;
            } else {
                state.status = Status.ERROR;
                state.message = action.payload.detail;
            }
        })
        builder.addCase(requestTransactionCreateTon.rejected, (state, action) => {
            state.status = Status.ERROR;
            state.message = 'Произошла ошибка при создании транзакции Ton';
        });
        // USDT Create Transaction
        builder.addCase(requestTransactionCreateUsdt.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestTransactionCreateUsdt.fulfilled, (state, action) => {
            if (action.payload.detail === 'Транзакция успешно создана') {
                state.status = Status.SUCCESS;
                state.message = action.payload.detail;
            } else {
                state.status = Status.ERROR;
                state.message = action.payload.detail;
            }
        })
        builder.addCase(requestTransactionCreateUsdt.rejected, (state, action) => {
            state.status = Status.ERROR;
            state.message = 'Произошла ошибка при создании транзакции USDT';
        });
        // Ton Get Transactions
        builder.addCase(requestGetTonTransactions.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetTonTransactions.fulfilled, (state, action) => {
            if (action.payload) {
                state.transactions = action.payload;
                state.status = Status.SUCCESS;
            }
        })
        builder.addCase(requestGetTonTransactions.rejected, (state) => {
            state.transactions = null;
            state.status = Status.ERROR;
        })
        // USDT Get Transactions
        builder.addCase(requestGetUsdtTransactions.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetUsdtTransactions.fulfilled, (state, action) => {
            if (action.payload) {
                state.transactions = action.payload;
                state.status = Status.SUCCESS;
            } else {
                state.status = Status.ERROR;
            }
        })
        builder.addCase(requestGetUsdtTransactions.rejected, (state) => {
            state.transactions = null;
            state.status = Status.ERROR;
        })
        // BTC Get Transactions
        builder.addCase(requestGetBitcoinTransactions.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetBitcoinTransactions.fulfilled, (state, action) => {
            if (action.payload) {
                state.transactions = action.payload;
                state.status = Status.SUCCESS;
            } else {
                state.status = Status.ERROR;
            }
        })
        builder.addCase(requestGetBitcoinTransactions.rejected, (state) => {
            state.transactions = null;
            state.status = Status.ERROR;
        })
    }
})

export const { setNullTransaction, setNullTransactions } = transactionSlice.actions;

export default transactionSlice.reducer;