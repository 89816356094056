type Props = {
    inn: string;
    setInn: React.Dispatch<React.SetStateAction<string>>;
}

const InnInput = ({ inn, setInn }: Props) => {
    const handleChange = (e: any) => {
        const inputValue = e.target.value;

        if (inputValue.length > 12) {
            return; // Не позволяем вводить больше 12 символов
        }

        setInn(e.target.value);
    }

    return (
        <div>
            <input
                value={inn}
                type="number"
                placeholder='Введите ИНН'
                onChange={handleChange}
            />
        </div>
    )
}

export default InnInput;