import { createAsyncThunk } from '@reduxjs/toolkit';

import { TransactionFormData } from './types';
import TransactionService from '../../services/TransactionService';


export const requestTransactionCreateBitcoin = createAsyncThunk('/bitcoin/transaction/create', async (formData: TransactionFormData) => {
    const response = await TransactionService.transactionCreateBitcoin(formData);
    return response;    
})

export const requestTransactionCreateTon = createAsyncThunk('/ton/transaction/create', async (formData: TransactionFormData) => {
    const response = await TransactionService.transactionCreateTon(formData);
    return response;
})

export const requestTransactionCreateUsdt = createAsyncThunk('/usdt/transaction/create', async (formData: TransactionFormData) => {
    const response = await TransactionService.transactionCreateUsdt(formData);
    return response;
})

export const requestGetTonTransactions = createAsyncThunk('/ton/transaction/get-all', async () => {
    const response = await TransactionService.getTransactionsTon();
    return response;
})

export const requestGetUsdtTransactions = createAsyncThunk('/usdt/transaction/get-all', async () => {
    const response = await TransactionService.getTransactionsUsdt();
    return response;
})

export const requestGetBitcoinTransactions = createAsyncThunk('/bitcoin/transaction/get-all', async () => {
    const response = await TransactionService.getTransactionsBtc();
    return response;
})