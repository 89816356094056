import './Step2.css';
import style from './Step2.module.scss';

import { useState } from 'react';

// Axios
import axios from 'axios';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Ant Design
import { message as antdMessage } from 'antd';

// Number
import ReactCodeInput from 'react-code-input';
import Timer from '../../../components/timer/Timer';


const Step2Verify = () => {
    const navigate = useNavigate();

    const [code, setCode] = useState<string>();
    let { phone } = useParams();

    // Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    const confirmCode = () => {
        if (!code) {
            messageApi.error('Введите код из смс');
            return;
        }
        if (code?.length !== 4) {
            messageApi.error('Код должен состоять из 4 символов');
            return;
        }

        const formData = {
            'code': code,
            'phone': phone,
            'type': 'VERIFICATION'
        }

        // Отправка запроса на подтверждения кода
        axios.patch('https://crm.fin-fresh.ru/api/clients/verify_phone', formData)
        .then((res) => {
            messageApi.success('Телефон успешно подтвержден!');

            const jwt_token = res.data.data.token;
            const client_id = res.data.data.client_id;
            localStorage.setItem('jwt_token_finfresh', jwt_token);
            localStorage.setItem('client_id_finfresh', client_id);

            // Редирект на страницу Alert
            navigate('/home/verification/step3');
        })
        .catch((err) => {
            if (err.response.data.message) {
                messageApi.error(err.response.data.message);
            }
        })
    }

    const sendSms = () => {
        const formData = {
            'phone': phone,
            'type': 'VERIFICATION',
        }
        axios.post('https://crm.fin-fresh.ru/api/sms/send_una', formData)
        .then((res) => {
            messageApi.success(res.data.message);
        })
        .catch((err) => {
            console.log('err: ', err);
        })
    }
    

    // Timer
    const [timeLeft, setTimeLeft] = useState(60);

    return (
        <div className={style.step2_verification}>
            {contextHolder}
            <h2>Введите код подтверждения</h2>
            <div className={style.step2_verification__info}>
                <p>Вам отправлен код на номер телефона</p>
                <p>+{phone}</p>
            </div>
            <div className={style.step2_verification__code}>
                <ReactCodeInput
                    fields={4}
                    value={code}
                    type='number'
                    inputMode={'tel'}
                    name={'phone_number'}
                    onChange={(value: string) => setCode(value)}
                />
            </div>
            <div className={style.step2_verification__info_repeat}>
                <Timer
                    startTime={60}
                    timeLeft={timeLeft}
                    actionBtn={sendSms}
                    setTimeLeft={setTimeLeft}
                    text={'Отправить код снова можно через'}
                />
            </div>
            <div className={style.step2_verification__btn}>
                <button onClick={confirmCode}>Подтвердить</button>
            </div>
        </div>
    )
}

export default Step2Verify;