import style from './Step1.module.scss';

// Axios
import axios from 'axios';

// React
import { useState } from 'react';

// Router
import { useNavigate } from 'react-router-dom';

// Images
import tinkoff from '../../../assets/img/tinkoff.png';
import gosUslugi from '../../../assets/img/gos_uslugi.png';

// Ant Design
import { message as antdMessage } from 'antd';

// Components
import FioInput from '../../../components/Forms/Fio/Fio';
import InnInput from '../../../components/Forms/Inn/Inn';
import PhoneInput from '../../../components/Forms/Phone/Phone';
import BirthDayInput from '../../../components/Forms/BirthDay/BirthDay';

// Utils
import { regexInn, regexPhone } from '../../../utils/regex';


const Step1Verify = () => {
    const navigate = useNavigate();

    // Form Inputs
    const [bitrhDay, setBirthDay] = useState<string | string[]>('');
    const [fio, setFio] = useState<string>('');
    const [phone, setPhone] = useState('');
    const [inn, setInn] = useState('');

    // Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    const ConfirmStep = () => {
        if (!regexPhone.test(phone)) {
            messageApi.error('Неверный формат номера телефона. Используйте +7XXXXXXXXXX или 7XXXXXXXXXX или 8XXXXXXXXXX.');
            return;
        }
        if (!fio) {
            messageApi.error('Заполните ФИО');
            return;
        }
        if (!regexInn.test(inn)) {
            messageApi.error('Неверный формат ИНН');
            return;
        }
        if (!bitrhDay) {
            messageApi.error('Заполните Дату Рождения');
            return;
        }

        let phoneEdit = phone;

        // Проверяем, начинается ли номер с 8 и заменяем на 7
        if (phone.startsWith('8')) {
            phoneEdit = '7' + phoneEdit.slice(1);
        }

        // Заменяем + на пустую строку
        phoneEdit = phoneEdit.replace('+', '');

        const fioSplit = fio.split(' ');

        const formData = {
            "phone": phoneEdit,
            "amount": 3000,
            "timezone": 3,
            "period": 1,
            "tariff_id": 328,
            "client_data": {
                "client": {
                    "last_name": fioSplit[0],
                    "first_name": fioSplit[1],
                    "middle_name": fioSplit[2],
                },
            }
        }

        axios.post('https://crm.fin-fresh.ru/api/clients/create', formData)
        .then((res) => {
            if (res.data.code === 200) {
                navigate(`/home/verification/step2/${phoneEdit}`);
            }
        })
        .catch((err) => {
            if (err.response.data.message) {
                messageApi.error(err.response.data.message);
            }
        })
    }

    return (
        <div className={style.step1_verification}>
            {contextHolder}
            <h2>Пройдите верификацию KYC</h2>
            <div className={style.step1_verification__choose}>
                <p className={style.step1_verification__choose__subtitle}>С помощью</p>
                <div className={style.step1_verification__choose__icons}>
                    <div className={style.step1_verification__choose__icons__icon}>
                        <img src={tinkoff} alt="tinkoff-icon" />
                        <p>Т-банк</p>
                    </div>
                    <div className={style.step1_verification__choose__icons__icon}>
                        <img src={gosUslugi} alt="gosuslugi-icon" />
                        <p>Госуслуги</p>
                    </div>
                </div>
            </div>
            <div className={style.step1_verification__block_fields}>
                <p className={style.step1_verification__block_fields__subtitle}>Введите данные вручную</p>
                <div className={style.step1_verification__block_fields__form}>
                    <PhoneInput phone={phone} setPhone={setPhone} />
                    <FioInput fio={fio} setFio={setFio} messageApi={messageApi} />
                    <InnInput inn={inn} setInn={setInn} />
                    <BirthDayInput setBirthDay={setBirthDay} />
                </div>
                <div className={style.step1_verification__block_fields__btn}>
                    <button onClick={ConfirmStep}>Отправить</button>
                </div>
            </div>
        </div>
    )
}

export default Step1Verify;