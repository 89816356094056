import './BirthDay.css';

import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import type { Dayjs } from 'dayjs';


type Props = {
    setBirthDay: React.Dispatch<React.SetStateAction<string | string[]>>;
}

const BirthDayInput = ({ setBirthDay }: Props) => {
    const dateFormat = 'DD.MM.YYYY';
    const onChange: DatePickerProps<Dayjs[]>['onChange'] = (date, dateString) => {
        setBirthDay(dateString);
    };

    return (
        <DatePicker
            needConfirm
            onChange={onChange}
            format={dateFormat}
            placeholder='Дата рождения'
            style={{width: '100%', height: '48px', fontSize: '17px', lineHeight: '22px', padding: '15px 16px', borderRadius: '12px', color: '#A2ACB0'}}
        />
    )
}

export default BirthDayInput;