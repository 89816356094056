import style from './Phone.module.scss';


type Props = {
    phone: string;
    setPhone: React.Dispatch<React.SetStateAction<string>>;
}


const PhoneInput = ({ phone, setPhone }: Props) => {
    const handleChange = (e: any) => {
        const inputValue = e.target.value;

        if (inputValue.includes('+')) {
            // Ограничиваем ввод до 12 символов
            if (inputValue.length > 12) {
                return; // Не позволяем вводить больше 12 символов
            }
        } else {
            // Ограничиваем ввод до 11 символов
            if (inputValue.length > 11) {
                return; // Не позволяем вводить больше 11 символов
            }
        }

        setPhone(inputValue);
    };

    return (
        <input
            required
            type="text"
            value={phone}
            onChange={handleChange}
            className={style.input_phone}
            placeholder='Номер телефона'
            pattern="^(?:\+7|8)?\s*\(?\d{3}\)?\s*\d{3}[- ]?\d{2}[- ]?\d{2}$"
            title="Введите номер телефона в формате +7 (123) 456-78-90 или 8 (123) 456-78-90"
        />
    )
}

export default PhoneInput;