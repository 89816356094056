import style from './Timer.module.scss';

import { useEffect, useState } from "react";

type Props = {
    text: string;
    timeLeft: number;
    startTime: number;
    actionBtn: () => void;
    setTimeLeft: React.Dispatch<React.SetStateAction<number>>
}

const Timer = ({ text, timeLeft, setTimeLeft, actionBtn }: Props) => {
    const [isTimerActive, setIsTimerActive] = useState(true);

    useEffect(() => {
        if (timeLeft > 0 && isTimerActive) {
            const timerId = setInterval(() => {
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);

            return () => clearInterval(timerId); // Очистка интервала при размонтировании
        } else {
            setIsTimerActive(false); // Останавливаем таймер, когда время закончилось
        }
    }, [timeLeft, isTimerActive]);

    const handleReset = () => {
        actionBtn();
        setTimeLeft(60);
        setIsTimerActive(true);
    };

    return (
        <div className={style.timer}>
            {isTimerActive && <h1>{text} {timeLeft} секунд</h1>}
            {!isTimerActive && (
                <button className={style.timer__btn} onClick={handleReset}>Повторить запрос</button>
            )}
        </div>
    );
};

export default Timer;
