import $api, { API_URL } from '../http';

export default class WalletsService {
    static async getWalletTon() {
        const res = await $api.post(`${API_URL}/ton/wallet/ton`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async getWalletBtc() {
        const res = await $api.post(`${API_URL}/bitcoin/wallet/bitcoin`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async getWalletUsdt() {
        const res = await $api.post(`${API_URL}/usdt/wallet/usdt`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async getQrCodeTon() {
        const res = await $api.get(`${API_URL}/ton/wallet/qr_code`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async getQrCodeBtc() {
        const res = await $api.get(`${API_URL}/bitcoin/wallet/qr_code`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async getQrCodeUsdt() {
        const res = await $api.get(`${API_URL}/ton/wallet/qr_code`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}