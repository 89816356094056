import style from './Step3.module.scss';

// Router
import { useNavigate } from 'react-router-dom';


const Step3Verify = () => {
    const navigate = useNavigate();

    const navigateToProfile = () => {
        navigate('/home/profile');
    }

    return (
        <div className={style.step3}>
            <div className={style.step3__block_verify}>
                <div className={style.step3__block_verify__up_block}>
                    <h2>Успешно</h2>
                    <p className={style.step3__block_verify__up_block__text_first}>Вы прошли верификацию.</p>
                    <p className={style.step3__block_verify__up_block__text_second}>Необходимо выбрать карту для получения средств</p>
                </div>
                <button onClick={navigateToProfile}>Выбрать карту</button>
            </div>
        </div>
    )
}

export default Step3Verify;