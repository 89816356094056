import './assets/css/App.scss';

// Router
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Redux
import { ReduxProvider } from './redux/provider';

// Telegram
import { useWebApp } from '@vkruglikov/react-telegram-web-app';

// Layouts
import { MainLayout } from './layouts/layouts';

// Pages
import Card from './pages/card/Card';
import Step1 from './pages/Step1/Step1';
import Wallet from './pages/wallet/Wallet';
import Step2 from './pages/Step1/Step2/Step2';
import Step3 from './pages/Step1/Step3/Step3';
import Welcome from './pages/welcome/Welcome';
import Profile from './pages/profile/Profile';
import Step4 from './pages/Step1/Step4/Step4';
import Step1Verify from './pages/verification/Step1/Step1';
import Step2Verify from './pages/verification/Step2/Step2';
import Step3Verify from './pages/verification/Step3/Step3';
import CurrencyInfo from './pages/currencyInfo/CurrencyInfo';
import TransactionInfo from './pages/transactionInfo/TransactionInfo';


function App() {
  // Setup TG Color
  const WebApp = useWebApp();

  WebApp.headerColor = '#007AFF';

  // Full Screen TG
  if (!WebApp.isExpanded) {
    WebApp.expand();
  }

  // Enable Closing Confirm
  WebApp.enableClosingConfirmation();

  return (
    <ReduxProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Welcome />} />
          <Route path="/home" element={<MainLayout />}>
            <Route index element={<Wallet />} />
            <Route path='card' element={<Card />} />
            <Route path='profile' element={<Profile />} />
            <Route path='transaction/:cryptoName/:transactionId' element={<TransactionInfo />} />
            <Route path='currency-info/:cryptoName/' element={<CurrencyInfo />} />
            <Route path='add-money/:type/:cryptoName/step1' element={<Step1 />} />
            <Route path='add-money/:type/:cryptoName/:action/step2' element={<Step2 />} />
            <Route path='add-money/:type/:cryptoName/:action/step3' element={<Step3 />} />
            <Route path='add-money/:type/:cryptoName/:action/:wallet/step4' element={<Step4 />} />
            <Route path='verification/step1' element={<Step1Verify />} />
            <Route path='verification/step2/:phone' element={<Step2Verify />} />
            <Route path='verification/step3' element={<Step3Verify />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
