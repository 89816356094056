import style from './Transactions.module.scss';

import { Spin } from 'antd';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Status } from '../../utils/utils';
import { useAppDispatch } from '../../redux/store';
import { LoadingOutlined } from '@ant-design/icons';
import { selectTransaction } from '../../redux/transaction/selectors';
import { requestGetBitcoinTransactions, requestGetTonTransactions, requestGetUsdtTransactions } from '../../redux/transaction/asyncActions';


type Props = {
    cryptoName: string | undefined;
    activeWallet: any;
}

const Transactions = ({ cryptoName, activeWallet }: Props) => {
    // Redux
    const dispatch = useAppDispatch();

    // Get Wallets
    const { status, transactions } = useSelector(selectTransaction);

    useEffect(() => {
        if (cryptoName === 'ton') {
            dispatch(requestGetTonTransactions())
        } else if (cryptoName === 'usdt') {
            dispatch(requestGetUsdtTransactions());
        } else if (cryptoName === 'btc') {
            dispatch(requestGetBitcoinTransactions())
        }
    }, [])

    // Получение ссылки на обозревателя
    const generateLinkExplorer = (): string => {
        if (cryptoName === 'ton' || cryptoName === 'usdt') {
            return `https://tonscan.org/ru/address/${activeWallet.address}`;
        } else {
            return `https://www.blockchain.com/explorer/addresses/btc/${activeWallet.address}`;
        }
    }

    return (
        <div className={style.currency_info__transactions}>
            <h2>История Транзакций</h2>
            {status === Status.LOADING
                ? (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Spin indicator={<LoadingOutlined />} size='large' />
                    </div>
                )
                : (
                    <div className={style.currency_info__transactions__items}>
                        {transactions && transactions?.length > 0
                            ? transactions?.map((el, idx) => (
                                el.action === 'Пополнение'
                                    ? (
                                        <div className={style.transaction_add} key={idx}>
                                            <div className={style.transaction_add__left}>
                                                <p className={style.transaction_title}>Пополнение</p>
                                                <p className={style.transaction_date}>{el.date_trx}</p>
                                            </div>
                                            <div className={style.transaction_right}>
                                                <p className={style.transaction_add_coint}>+{el.value}</p>
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <div className={style.transaction_transfer} key={idx}>
                                            <div className={style.transaction_add__left}>
                                                <p className={style.transaction_title}>Перевод</p>
                                                <p className={style.transaction_date}>{el.date_trx}</p>
                                            </div>
                                            <div className={style.transaction_right}>
                                                <p className={style.transaction_add_coint}>-{el.value}</p>
                                            </div>
                                        </div>
                                    ))) 
                            : (
                            <div style={{textAlign: 'center'}}>
                                <p>Здесь будут отображаться транзакции.</p>
                                <p>Не удается найти транзакцию? <a style={{color: '#007AFF'}} href={generateLinkExplorer()}>Посмотреть обозреватель</a></p>
                            </div>
                        )}
                    </div>
                )}
        </div>
    )
}

export default Transactions;