import style from './Welcome.module.scss';

// React
import { useEffect, useState } from 'react';

// Images
import mainPhoto from '../../assets/img/welcome/main_photo.png';

// Ant Design
import { message as antdMessage } from 'antd';

// Router
import { useNavigate } from 'react-router-dom';

// Redux
import { useAppDispatch } from '../../redux/store';
import { AuthFormData } from '../../redux/auth/types';
import { requestAuth } from '../../redux/auth/asyncActions';

// Telegram
import { MainButton, useWebApp, WebAppUser } from '@vkruglikov/react-telegram-web-app';

// Redux
import { useSelector } from 'react-redux';
import { selectAuth } from '../../redux/auth/selectors';
import { requestGetWalletBtc, requestGetWalletTon, requestGetWalletUsdt } from '../../redux/wallets/asyncActions';

// Utils
import { Status } from '../../utils/utils';

// Components
import Loading from '../../components/spinner/Spinner';


const Welcome = () => {
    const WebApp = useWebApp();
    const user: WebAppUser = WebApp.initDataUnsafe.user;
    const [isClearLocalStorage, setIsClearLocalStorage] = useState<boolean>(false);

    const { status, message } = useSelector(selectAuth);

    // Redux
    const dispatch = useAppDispatch();

    // Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Get Token
    if (!isClearLocalStorage) {
        // localStorage.clear();
        localStorage.removeItem('token')
        setIsClearLocalStorage(true);
    }
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            // Request Get Token
            const formData: AuthFormData = {
                'telegram_id': user.id ? `${user.id}` : null,
                'first_name': user.first_name ? user.first_name : null,
                'last_name': user.last_name ? user.last_name : null,
                'username': user.username ? user.username : null,
            }
            // const formData: AuthFormData = {
            //     'telegram_id': '111222333',
            //     'first_name': 'Dev',
            //     'last_name': 'Jedi',
            //     'username': 'dev_jedi',
            // }
            dispatch(requestAuth(formData));
        }
    }, [token, dispatch, user])

    useEffect(() => {
        if (status === Status.SUCCESS) {
            dispatch(requestGetWalletTon());
            dispatch(requestGetWalletBtc());
            dispatch(requestGetWalletUsdt())
        }
    }, [token, status, dispatch])

    useEffect(() => {
        if (message.length > 0) {
            messageApi.error(message, 2);
        }
    }, [message])


    // Navigate
    const navigate = useNavigate();

    const navigateTo = () => {
        navigate('/home');
    }

    return (
        <div className={style.welcome}>
            {contextHolder}
            {status === Status.LOADING
                ? <Loading />
                : (
                    <>
                        <div className={style.welcome__img_block}>
                            <img src={mainPhoto} alt="main-photo" />
                        </div>
                        <div className={style.welcome__title}>
                            <h1>Первый заём – бесплатно!</h1>
                            <p>До 21 дня — без процентов*</p>
                        </div>
                        <MainButton text='Продолжить' color='#007AFF' onClick={navigateTo} />
                    </>
                )}
        </div>
    )
}

export default Welcome;