import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../utils/utils';
import { WalletObject, WalletsSliceState } from './types';
import { requestGetWalletBtc, requestGetWalletTon, requestGetWalletUsdt } from './asyncActions';


const initialState: WalletsSliceState = {
    message: '',
    ton_wallet: null,
    btc_wallet: null,
    usdt_wallet: null,
    status: Status.CALM as Status,
}

const walletsSlice = createSlice({
    name: 'wallets',
    initialState,
    reducers: {
        setBtcBalance(state, action) {
            const { address, balance_usd, balance_crypto } = action.payload;
            const btcData: WalletObject = {
                address: address,
                balance_usd: balance_usd,
                balance_crypto: balance_crypto,
            }
            state.btc_wallet = btcData;
        },
        setTonBalance(state, action) {
            const { address, balance_usd, balance_crypto } = action.payload;
            const tonData: WalletObject = {
                address: address,
                balance_usd: balance_usd,
                balance_crypto: balance_crypto,
            }
            state.ton_wallet = tonData;
        },
        setTetherBalance(state, action) {
            const { address, balance_usd, balance_crypto } = action.payload;
            const tetherData: WalletObject = {
                address: address,
                balance_usd: balance_usd,
                balance_crypto: balance_crypto,
            }
            state.usdt_wallet = tetherData;
        }
    },
    extraReducers: (builder) => {
        // Get Ton Wallet and Balance
        builder.addCase(requestGetWalletTon.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetWalletTon.fulfilled, (state, action) => {
            if (action.payload) {
                state.status = Status.SUCCESS;
                state.ton_wallet = action.payload;
            } else {
                state.status = Status.ERROR;
                state.message = 'Произошла ошибка при получении TON кошелька';
            }
        })
        builder.addCase(requestGetWalletTon.rejected, (state) => {
            state.status = Status.ERROR;
            state.message = 'Произошла ошибка при получении TON кошелька';
        })
        // Get BTC Wallet and Balance
        builder.addCase(requestGetWalletBtc.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetWalletBtc.fulfilled, (state, action) => {
            if (action.payload) {
                state.status = Status.SUCCESS;
                state.btc_wallet = action.payload;
            } else {
                state.status = Status.ERROR;
                state.message = 'Произошла ошибка при получении BTC кошелька';
            }
        })
        builder.addCase(requestGetWalletBtc.rejected, (state) => {
            state.status = Status.ERROR;
            state.message = 'Произошла ошибка при получении BTC кошелька';
        })
        // Get USDT Wallet and Balance
        builder.addCase(requestGetWalletUsdt.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetWalletUsdt.fulfilled, (state, action) => {
            if (action.payload) {
                state.status = Status.SUCCESS;
                state.usdt_wallet = action.payload;
            } else {
                state.status = Status.ERROR;
                state.message = 'Произошла ошибка при получении USDT кошелька';
            }
        })
        builder.addCase(requestGetWalletUsdt.rejected, (state) => {
            state.status = Status.ERROR;
            state.message = 'Произошла ошибка при получении USDT кошелька';
        })
    }
})

export const { setBtcBalance, setTonBalance, setTetherBalance } = walletsSlice.actions;

export default walletsSlice.reducer;