import { useEffect, useState } from 'react';

import { MessageInstance } from 'antd/es/message/interface';

type Props = {
    fio: string;
    messageApi: MessageInstance;
    setFio: React.Dispatch<React.SetStateAction<string>>;
}

const FioInput = ({ fio, setFio, messageApi }: Props) => {
    const [error, setError] = useState<string>('');

    const handleChange = (e: any) => {
        const inputValue = e.target.value;

        // Ограничиваем ввод до 100 символов
        if (inputValue.length > 100) {
            return; // Не позволяем вводить больше 100 символов
        }

        setFio(inputValue);
        setError(''); // Сбрасываем ошибку при изменении ввода
    };

    const handleBlur = () => {
        // Проверяем количество слов
        const words = fio.trim().split(/\s+/); // Разделяем по пробелам
        if (words.length !== 3) {
            setError('Пожалуйста, введите Фамилию, Имя и Отчество (3 слова).');
        }
    };

    useEffect(() => {
        if (error.length > 0) {
            messageApi.error(error);
        }
    }, [messageApi, error])

    return (
        <div>
            <input
                required
                type="text"
                value={fio}
                placeholder='ФИО'
                onBlur={handleBlur}
                onChange={handleChange}
            />
        </div>
    );
};

export default FioInput;
